.home-container {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    color: #343a40;
    padding: 20px;
  }
  
  .home-header {
    background: #007bff;
    color: #fff;
    padding: 2rem 1rem;
  }
  
  .home-header h1 {
    font-size: 3rem;
    margin: 0;
  }
  
  .home-header p {
    font-size: 1.2rem;
    margin: 0.5rem 0 1rem;
  }
  
  .home-nav {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .nav-link {
    color: #fff;
    text-decoration: none;
    background: #0056b3;
    padding: 0.5rem 1rem;
    border-radius: 4px;
  }
  
  .nav-link:hover {
    background: #004494;
  }
  
  .home-content {
    margin: 2rem auto;
    max-width: 800px;
    padding: 1rem;
  }
  
  .cta-button {
    display: inline-block;
    margin-top: 1.5rem;
    padding: 0.75rem 1.5rem;
    background: #28a745;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .cta-button:hover {
    background: #218838;
  }
  
  .home-footer {
    margin-top: 2rem;
    font-size: 0.9rem;
    color: #777;
  }
  