.dashboard-layout {
  display: flex;
  min-height: 100vh;
  font-family: 'Segoe UI', sans-serif;
  position: relative;
}

/* Sidebar Styles */
.sidebar {
  width: 260px;
  background: #212529;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

/* Sidebar Header */
.sidebar-header h1 {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  color: #ffa100;
}

.sidebar-header p {
  font-size: 0.9rem;
  color: #ccc;
}

/* Menu Links */
.menu ul {
  list-style: none;
  padding: 0;
  margin-top: 2rem;
}

.menu li {
  margin-bottom: 1.2rem;
}

.menu a {
  color: #ccc;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: 0.5rem 0.8rem;
  border-radius: 6px;
  transition: background 0.2s ease, color 0.2s ease;
}

.menu a:hover {
  background: #343a40;
  color: #ffa100;
}

.menu a.active {
  background: #343a40;
  color: #ffa100;
  font-weight: bold;
}

/* Sidebar Footer */
.sidebar-actions {
  margin-top: auto;
  padding-top: 2rem;
}

.sidebar-actions button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  justify-content: center;
  transition: background 0.3s ease;
}

.sidebar-actions button:hover {
  background-color: #0056b3;
}

/* Main Content */
.main-content {
  flex: 1;
  background: #f1f3f5;
  padding: 2rem;
  overflow-y: auto;
  width: 100%;
}

/* Mobile Header */
.mobile-header {
  display: none;
  background-color: #212529;
  color: #fff;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
}

.hamburger-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Responsive Behavior */
@media (max-width: 768px) {
  .dashboard-layout {
    flex-direction: column;
  }

  .sidebar {
    position: absolute;
    top: 60px;
    left: 0;
    height: calc(100% - 60px);
    z-index: 1000;
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .main-content {
    padding: 1rem;
  }

  .mobile-header {
    display: flex;
  }
}
