/* src/components/admin/AdminProfile.css */
.admin-profile-page {
    padding: 30px;
}

.admin-profile-page h2 {
    font-size: 1.75rem;
    margin-bottom: 20px;
    color: #2a7ae4;
}

.admin-profile-form {
    max-width: 600px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.05);
}

.admin-profile-form .form-group {
    margin-bottom: 15px;
}

.admin-profile-form .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: #333;
}

.admin-profile-form .form-group input,
.admin-profile-form .form-group textarea,
.admin-profile-form .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
}

.admin-profile-form .form-group textarea {
    resize: vertical;
}

.admin-profile-form button {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #2a7ae4;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.admin-profile-form button:hover {
    background-color: #1f5bb5;
}

.update-message {
    margin-top: 20px;
    text-align: center;
    font-size: 1rem;
    color: #842029;
}

.admin-profile-page {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .admin-profile-content {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .admin-profile-form {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #eee;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #333;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  button {
    background-color: #2a7ae4;
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;
  }
  
  button:hover {
    background-color: #1a5ab5;
  }
  
  .password-reset-section {
    margin-top: 2rem;
  }
  
  .password-reset-section h3 {
    margin-bottom: 1rem;
  }
  
  .password-reset-btn {
    background-color: #4CAF50;
  }
  
  .password-reset-btn:hover {
    background-color: #45a049;
  }
  
  .update-message {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 4px;
    background-color: #e8f5e9;
    color: #2e7d32;
  }
  
  .update-message.error {
    background-color: #ffebee;
    color: #d32f2f;
  }