.patent-form-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.tab {
  padding: 0.5rem 1rem;
  border: 1px solid #ced4da;
  background: #f8f9fa;
  color: #343a40;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease, border-color 0.3s ease;
}

.tab:hover {
  background: #e2e6ea;
}

.tab.active {
  background: #007bff;
  border-color: #007bff;
  color: #fff;
}

.form-content {
  margin-bottom: 1rem;
}

.step-content {
  margin-bottom: 1rem;
}

.inventor-entry, .guardian-entry {
  border: 1px solid #ced4da;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  background: #f9f9f9;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  font-weight: 500;
  margin-bottom: 0.25rem;
  color: #495057;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"],
.form-group input[type="file"],
textarea,
select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

.tall-input {
  min-height: 3rem;
}

.tall-textarea {
  min-height: 200px;
  padding: 0.75rem;
  font-size: 1rem;
  resize: vertical;
}

.add-inventor-button {
  padding: 0.5rem 1rem;
  background: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.add-inventor-button:hover {
  background: #218838;
}

.form-navigation {
  text-align: right;
}

.form-navigation button {
  padding: 0.75rem 1.5rem;
  margin-left: 1rem;
  border: none;
  background: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.form-navigation button:hover {
  background: #0056b3;
}

.required {
  color: red;
}

.guardian-section h4 {
  margin-top: 1rem;
  font-size: 1rem;
  color: #333;
}
