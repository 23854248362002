/* General Static Page Container */
.static-page,
.faq-container,
.resources-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', sans-serif;
  color: #343a40;
}

/* Page Backgrounds */
.static-page-wrapper,
.faq-page,
.resources-page {
  background: #f8f9fa;
  padding: 40px 20px;
}

/* Page Titles */
.static-page h1,
.faq-container h1,
.resources-container h1 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1.5rem;
  color: #007bff;
}

/* Section Headings */
.static-page h2,
.faq-item h2,
.resource-content h2 {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #007bff;
}

/* Paragraphs */
.static-page p,
.faq-container p,
.resources-container p,
.resource-content p {
  font-size: 1rem;
  line-height: 1.7;
  margin-bottom: 1rem;
  color: #495057;
}

/* Lists */
.static-page ul,
.faq-item ul,
.resource-content ul {
  list-style: none;
  padding-left: 1.2rem;
  margin-bottom: 1rem;
}

.static-page ul li,
.faq-item ul li,
.resource-content ul li {
  position: relative;
  margin-bottom: 0.6rem;
  padding-left: 1rem;
  color: #495057;
  font-size: 1rem;
}

.static-page ul li::before,
.faq-item ul li::before,
.resource-content ul li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #2a7ae4;
  font-size: 1.2rem;
}

/* FAQ-specific */
.faq-item {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.faq-item:last-child {
  border-bottom: none;
}

/* Resource-specific */
.resources-container a {
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
  color: #007bff;
  font-weight: 500;
  transition: color 0.3s ease;
}

.resources-container a:hover {
  color: #0056b3;
}

/* Reusable link style */
.static-page a {
  color: #007bff;
  text-decoration: none;
}

.static-page a:hover {
  text-decoration: underline;
}

/* Message Box Styling */
.static-page .message-box {
  padding: 1rem;
  border-radius: 6px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #212529;
  margin: 1.5rem 0;
}

/* Responsive */
@media (max-width: 768px) {
  .static-page,
  .faq-container,
  .resources-container {
    padding: 1.5rem;
  }

  .static-page h1,
  .faq-container h1,
  .resources-container h1 {
    font-size: 1.6rem;
  }

  .static-page h2,
  .faq-item h2,
  .resource-content h2 {
    font-size: 1.25rem;
  }

  .static-page p,
  .faq-container p,
  .resources-container p,
  .resource-content p,
  .resource-content ul li {
    font-size: 0.95rem;
  }
}

.blog-list {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.blog-post h2 {
  font-size: 1.4rem;
  color: #007bff;
  margin-bottom: 0.5rem;
}

.blog-post p {
  color: #495057;
  margin-bottom: 0.5rem;
}

.blog-post a {
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
}

.blog-post a:hover {
  text-decoration: underline;
}
