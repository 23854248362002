.application-details-container {
  padding: 30px;
  display: flex;
  justify-content: center;
}

.application-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  max-width: 850px;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.07);
  font-family: 'Segoe UI', sans-serif;
}

.application-card h2 {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 10px;
}

.application-number {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 25px;
}

.section h3 {
  font-size: 1.2rem;
  color: #007bff;
  margin-bottom: 8px;
}

.section p {
  font-size: 1rem;
  color: #444;
  margin: 4px 0;
}

.inventor-details {
  background: #f8f9fa;
  padding: 10px 15px;
  border-radius: 6px;
  margin-bottom: 10px;
}

.drawing-image {
  max-width: 100%;
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.button-section {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.action-button {
  flex: 1;
  padding: 12px 20px;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background 0.3s ease;
}

.action-button.update {
  background-color: #007bff;
}

.action-button.update:hover {
  background-color: #0056b3;
}

.action-button.checkout {
  background-color: #28a745;
}

.action-button.checkout:hover {
  background-color: #218838;
}

.submitted-message {
  color: #155724;
  background: #d4edda;
  padding: 10px 15px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
}

.loading-message {
  text-align: center;
  margin-top: 50px;
  font-size: 1.2rem;
  color: #888;
}

.guardian-details {
  margin-top: 1rem;
  padding: 0.75rem;
  background-color: #f9f9f9;
  border-left: 4px solid #007bff;
  border-radius: 6px;
}

