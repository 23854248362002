.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: #f8f9fa;
  }
  
  .login-card {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.1);
    width: 360px;
    text-align: center;
  }
  
  .login-card h2 {
    margin-bottom: 0.5rem;
    color: #007bff;
    font-size: 2rem;
  }
  
  .login-card h3 {
    margin-bottom: 1.5rem;
    color: #343a40;
  }
  
  .input-group {
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 0.25rem;
    color: #495057;
    font-weight: 500;
  }
  
  .input-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .login-button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    background: #28a745;
    color: #fff;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .login-button:hover {
    background: #218838;
  }
  
  .login-footer {
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  
  .login-footer a {
    color: #007bff;
    text-decoration: none;
  }
  
  .login-footer a:hover {
    text-decoration: underline;
  }
  
  /* Add these to existing Login.css */
.error-message {
  color: #dc3545;
  margin-bottom: 1rem;
  text-align: center;
}

.success-message {
  color: #28a745;
  margin-bottom: 1rem;
  text-align: center;
}

.forgot-password {
  text-align: right;
  margin: 0.5rem 0 1.5rem;
}

.forgot-password button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 0;
  font-size: 0.9em;
}

.forgot-password button:hover {
  text-decoration: underline;
}