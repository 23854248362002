/* src/components/admin/AdminPage.css */
.admin-page {
  padding: 30px;
}

.admin-page h2 {
  font-size: 1.75rem;
  margin-bottom: 20px;
  color: #2a7ae4;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.05);
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.info-list {
  padding-left: 1rem;
  list-style: disc;
  color: #555;
}

.guardian-details {
  margin-top: 1rem;
  padding: 0.75rem 1rem;
  border-left: 4px solid #007bff;
  background-color: #f8f9fa;
  border-radius: 6px;
}

.delete-button {
  background-color: #ff4d4f;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 15px;
  right: 15px;
}

.delete-button:hover {
  background-color: #d9363e;
}

.warning-message {
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  color: #842029;
  padding: 15px;
  border-radius: 4px;
  font-size: 1rem;
  margin: 20px 0;
  text-align: center;
}

.application-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.application-table th,
.application-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.application-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.application-table tr:hover {
  background-color: #f9f9f9;
}

.actions {
  display: flex;
  gap: 10px;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 1.2em;
  transition: opacity 0.2s;
}

.icon-button:hover {
  opacity: 0.7;
}

.view-button {
  color: #2196F3;
}

.delete-button {
  color: #f44336;
}

.action-btn {
  padding: 8px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.1rem;
  transition: opacity 0.2s;
}

.action-btn:hover {
  opacity: 0.7;
}

/* OrderList specific columns */
.application-table .col-order-number { 
  width: 7%;
  min-width: 80px;
}
.application-table .col-title { width: 35%; }
.application-table .col-plan { width: 10%; }
.application-table .col-amount { width: 10%; }
.application-table .col-date { width: 15%; }
.application-table .col-status { width: 13%; }
.application-table .col-actions { width: 10%; }

/* ApplicationList specific columns */
.application-table .col-app-title { width: 40%; }
.application-table .col-patent-type { width: 15%; }
.application-table .col-inventor { width: 15%; }
.application-table .col-created { width: 15%; }
.application-table .col-app-actions { width: 15%; }

/* Numeric alignment - UPDATED FOR CENTER ALIGN */
.application-table td.col-amount {
  text-align: center;
  padding: 12px 15px; /* Use standard padding instead of right-padding */
}

/* OrderList specific columns - ADJUSTED FOR CENTERED AMOUNT */
.application-table .col-order-number { 
  width: 7%;
  min-width: 80px;
}
.application-table .col-title { width: 35%; }
.application-table .col-plan { width: 10%; }
.application-table .col-amount { 
  width: 10%;
  text-align: center; /* Added for header alignment */
}
.application-table .col-date { width: 15%; }
.application-table .col-status { width: 13%; }
.application-table .col-actions { width: 10%; }

/* Fix button alignment in ApplicationList */
.application-table .col-app-actions {
  width: 15%;
}

.actions {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
}

/* Update icon button styles */
.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px 8px;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
}

.view-button {
  color: #2196F3;
  background-color: #e3f2fd;
  border: 1px solid #2196F3;
}

.delete-button {
  color: #f44336;
  background-color: #ffebee;
  border: 1px solid #f44336;
}

/* Remove absolute positioning from delete button */
.delete-button {
  position: static;
  top: auto;
  right: auto;
}