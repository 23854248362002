.footer {
  background: #343a40;
  color: #fff;
  padding: 2rem 1rem;
  font-family: 'Roboto', sans-serif;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2rem;
  margin-bottom: 1rem;
}

.footer-section {
  flex: 1 1 220px;
  min-width: 200px;
}

.footer-section h4 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #ffa100;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.footer-bottom {
  text-align: center;
  font-size: 0.9rem;
  border-top: 1px solid #555;
  padding-top: 1rem;
}

/* Newsletter Section Styles */
.subscription-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.newsletter-input {
  flex: 1 1 auto;
  min-width: 180px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.subscribe-button {
  flex-shrink: 0;
  padding: 6px 12px;
  font-size: 0.85rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 38px;
}

.subscribe-button:hover {
  background-color: #0056b3;
}

.subscription-message {
  margin-top: 0.5rem;
  font-size: 14px;
}

.error-message {
  color: #dc3545;
}

.success-message {
  color: #28a745;
}

/* Responsive */
@media (max-width: 600px) {
  .subscription-form {
    flex-direction: column;
  }

  .subscribe-button {
    width: 100%;
    height: 40px;
  }
}
