/* src/components/Contact.css */
.contact-page {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    max-width: 600px;
    margin: 2rem auto;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.contact-page h1 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #343a40;
}

.contact-form .form-group {
    margin-bottom: 1rem;
}

.contact-form label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #495057;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-bottom: 0.5rem;
}

.contact-form textarea {
    resize: vertical;
    min-height: 150px;
}

.submit-btn {
    width: 100%;
    padding: 0.75rem;
    margin-top: 1rem;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.submit-btn:hover {
    background: #0056b3;
}

.submit-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.message-banner {
    padding: 1em;
    margin: 1em 0;
    border-radius: 4px;
    text-align: center;
}

.message-banner.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.message-banner.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .contact-page {
        margin: 1rem;
        padding: 1.5rem;
    }
    
    .contact-page h1 {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .contact-page {
        padding: 1rem;
        margin: 0.5rem;
    }
    
    .contact-form textarea {
        min-height: 120px;
    }
}