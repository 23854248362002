.order-details-container {
    padding: 30px;
    display: flex;
    justify-content: center;
  }
  
  .order-card {
    background: #ffffff;
    border-radius: 10px;
    padding: 30px;
    max-width: 750px;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.07);
  }
  
  .order-card h2 {
    font-size: 1.7rem;
    color: #2a7ae4;
    margin-bottom: 25px;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 10px;
  }
  
  .section {
    margin-bottom: 25px;
  }
  
  .section h3 {
    font-size: 1.2rem;
    color: #343a40;
    margin-bottom: 10px;
  }
  
  .section p {
    font-size: 0.95rem;
    color: #444;
    margin: 4px 0;
  }
  
  .download-link {
    display: inline-block;
    background: #e7f3ff;
    color: #0366d6;
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
    text-decoration: none;
  }
  
  .download-link:hover {
    background: #d6eaff;
  }
  
  .view-app-button {
    background-color: #6f42c1;
    color: white;
    padding: 10px 18px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
  }
  
  .view-app-button:hover {
    background-color: #5b34a1;
  }
  
  .back-button {
    margin-top: 30px;
    background-color: #007bff;
    color: #fff;
    padding: 10px 18px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  
  .loading {
    text-align: center;
    margin-top: 40px;
    color: #666;
    font-size: 1.1rem;
  }
  
  .status-tag {
    font-weight: bold;
    padding: 4px 10px;
    border-radius: 12px;
    text-transform: capitalize;
  }
  
  .status-tag.pending {
    background-color: #fff3cd;
    color: #856404;
  }
  
  .status-tag.processing {
    background-color: #d1ecf1;
    color: #0c5460;
  }
  
  .status-tag.completed {
    background-color: #d4edda;
    color: #155724;
  }
  