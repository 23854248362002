.checkout-container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 1rem;
  text-align: center;
  font-family: Arial, sans-serif;
}

.pricing-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.pricing-option {
  border: 1px solid #ccc;
  padding: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pricing-option.selected {
  background-color: #e0f7fa;
  border-color: #00796b;
}

.checkout-button {
  padding: 1rem 2rem;
  font-size: 1rem;
  background-color: #00796b;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.checkout-button:hover {
  background-color: #005b4f;
}
