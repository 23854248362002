.orders-container {
    padding: 30px;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .loading-message,
  .empty-message {
    font-size: 1.1rem;
    color: #777;
    text-align: center;
    margin-top: 50px;
  }
  
  .orders-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
  }
  
  .order-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .order-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  }
  
  .order-card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    font-weight: 600;
  }
  
  .order-number {
    color: #2a7ae4;
  }
  
  .order-status {
    background-color: #f0f0f0;
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 0.85rem;
    color: #555;
  }
  
  .order-body h3.patent-title {
    margin: 5px 0 10px;
    font-size: 1.1rem;
    color: #333;
  }
  
  .order-date,
  .order-amount {
    font-size: 0.95rem;
    color: #666;
    margin-bottom: 6px;
  }
  
  .details-button {
    margin-top: 10px;
    padding: 8px 14px;
    background-color: #2a7ae4;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.95rem;
    align-self: flex-start;
  }
  
  .details-button:hover {
    background-color: #185abc;
  }
  