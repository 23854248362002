.payment-success {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }
  
  .payment-success h2 {
    color: #28a745;
    margin-bottom: 1rem;
  }
  
  .payment-success p {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #343a40;
  }
  
  .payment-success button {
    padding: 0.75rem 1.5rem;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .payment-success button:hover {
    background: #0056b3;
  }
  