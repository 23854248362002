/* src/components/StaticHeader.css */
.static-header {
  background-color: #2c3e50;
  padding: 1.5rem 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.logo-link {
  text-decoration: none;
  transition: transform 0.3s ease;
  display: inline-block;
}

.logo-text {
  color: #3498db;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: -0.5px;
  margin: 0;
  transition: color 0.3s ease;
  text-decoration: none;
}

.logo-text:hover {
  color: #ffffff;
}

.logo-link:hover {
  transform: translateY(-1px);
}

.logo-link:hover .logo-text {
  color: #3498db;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .static-header {
    padding: 1rem 0;
  }
  
  .header-container {
    padding: 0 1.5rem;
    text-align: center;
  }

  .logo-text {
    font-size: 1.75rem;
  }
}

@media (max-width: 480px) {
  .logo-text {
    font-size: 1.5rem;
  }
  
  .header-container {
    padding: 0 1rem;
  }
}