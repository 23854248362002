.applications-container {
  padding: 30px;
  max-width: 1000px;
  margin: 0 auto;
}

.applications-container h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.empty-message {
  text-align: center;
  color: #666;
  font-size: 1.1rem;
  margin-top: 50px;
}

.applications-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.application-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  position: relative;
}

.application-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.08);
}

.application-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #2a7ae4;
}

.application-meta {
  font-size: 0.95rem;
  color: #444;
  margin-bottom: 6px;
}

.submitted-tag {
  display: inline-block;
  margin-top: 10px;
  padding: 4px 10px;
  background-color: #d1e7dd;
  color: #0f5132;
  font-size: 0.85rem;
  border-radius: 6px;
  font-weight: 500;
}

/* New delete button styles */
.delete-button {
  background-color: #ff4d4f;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 15px;
  right: 15px;
}

.delete-button:hover {
  background-color: #d9363e;
}

.warning-message {
  background-color: #f8d7da;  /* light red background */
  border: 1px solid #f5c2c7;  /* matching border */
  color: #842029;            /* dark red text */
  padding: 15px;
  border-radius: 4px;
  font-size: 1rem;
  margin: 20px 0;
  text-align: center;
}
