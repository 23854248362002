/* src/components/admin/AdminDashboard.css */
.admin-home {
    padding: 30px;
  }
  
  .admin-home h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #343a40;
  }
  
  .admin-home p {
    color: #555;
    margin-bottom: 30px;
  }
  
  .admin-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 20px;
  }
  
  .stat-box {
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    transition: all 0.2s ease-in-out;
  }
  
  .stat-box:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
  }
  
  .stat-box h3 {
    font-size: 1.3rem;
    color: #007bff;
    margin-bottom: 0.5rem;
  }
  
  .stat-box p {
    color: #666;
    font-size: 0.95rem;
  }
  