.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* Optionally, if you want the main content to grow to fill the space */
  .app-container > .app-content {
    flex: 1;
  }
  