.profile-page {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  }
  
  .profile-page h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #343a40;
  }
  
  .profile-form .form-group {
    margin-bottom: 1rem;
  }
  
  .profile-form label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #495057;
  }
  
  .profile-form input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  
  .profile-form button {
    width: 100%;
    padding: 0.75rem;
    margin-top: 1rem;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .profile-form button:hover {
    background: #0056b3;
  }
  
  .update-message {
    margin-top: 1rem;
    text-align: center;
    color: green;
  }
  
  .password-strength {
    font-size: 0.9em;
    margin-top: 0.5em;
  }
  
  .password-strength.weak {
    color: #dc3545;
  }
  
  .message-banner {
    padding: 1em;
    margin: 1em 0;
    border-radius: 4px;
  }
  
  .message-banner.success {
    background-color: #d4edda;
    color: #155724;
  }
  
  .message-banner.error {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .read-only-field {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
  
  .update-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .password-reset-section {
    margin-top: 2rem;
    padding: 1.5rem;
    background: #f8f9fa;
    border-radius: 4px;
    text-align: center;
  }
  
  .password-reset-section h3 {
    margin-bottom: 1rem;
    color: #343a40;
  }
  
  .reset-password-button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    background: #28a745;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .reset-password-button:hover {
    background: #218838;
  }
  
  .reset-instructions {
    margin-top: 0.5rem;
    color: #6c757d;
    font-size: 0.9em;
  }